const routes = [
    {
        path: "/placement/exams",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "placementexam_index"
        }
    }
];

export default routes;
