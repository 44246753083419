import {getRoutePage,setRoutePage} from "@/modules/reservationSuccessPage/router/middleware";

const routes = [
    {
        path: "/reservation/success/page",
        component: () => import("../pages/Index"),
        beforeEnter: (to, from, next) => {
            if(getRoutePage()){
                setRoutePage(false)
                next()
            }else{
                next({
                    path: '/housing/tenant/login'
                })
            }
        }


    }
];

export default routes;
