const routes = [
    {
        path: "/translations",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "translation_index"
        }
    },

];

export default routes;
