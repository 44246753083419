const router = [
    {
        path:"/discipline/process",
        component:()=>import("../pages/DisciplineInformation"),
        meta:{
            isAuthenticated:true,
            permission:"discipline_index"
        }
    }
]
export default router
