const routes = [
    {
        path: "/student/courses/simulation/:id?",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcoursesimulation_create"
        }
    },
];

export default routes;
