import Api from '@/services/Index';

// PARAMETER
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameters', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameters/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/parameters/' + data.id, data);
    }
    return Api.post('/parameters', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/parameters/' + id, formData);
}

const deleteParameter = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/parameters/' + id);
}

// PARAMETER ITEMS
const getAllItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/items', config);
}

const getItems = async (code, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/' + code + '/items', config);
}

const getItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/items/' + id);
}

const storeItems = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/parameter/items', config);
}
const updateItems = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/parameter/items/' + id, formData);
}

const deleteItem = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/parameter/items/' + id);
}

const getItemsPublic = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/parameter/items/public', config);
}

export default {
    getAll,
    get,
    store,
    update,
    deleteParameter,

    getAllItems,
    getItems,
    getItem,
    storeItems,
    updateItems,
    deleteItem,
    getItemsPublic
}
