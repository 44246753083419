const routes = [
    {
        path: "/evaluations",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "evaluation_index"
        }
    }
];

export default routes;
