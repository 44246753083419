const routes = [
    {
        path: "/student/documents/create",
        component: () => import("../pages/Create"),
        meta: {
            isAuthenticated: true,
            permission: "studentdocument_create"
        }
    },
];

export default routes;
