<template>
    <div>
        <b-modal ref="modal" id="DeleteModal"
                 size="sm"
                 centered
                 :hide-footer="true"
                 :hide-header="true"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :visible="$store.getters.showDeleteModal">
            <template class="container">
                <div>{{ $t('warning') }}</div>
                <button type="button" aria-label="Close" class="close text-uppercase small" @click="close">{{ $t('close') }}</button>
            </template>
            <template>
                <div class="form-group">{{ $t('are_you_sure_to_delete') }}</div>
                <b-button type="submit" variant="danger" class="mt-0 btn-block" @click="onDelete">{{ $t('delete').toUpper() }}</b-button>
                <!-- content -->
            </template>
        </b-modal>
    </div>
</template>

<script>


    export default {
        name: 'DeleteModal',
        data: () => ({}),
        methods: {
            async onDelete() {
                await this.$store.getters.deleteModalCallback();
                this.close();
            },
            close() {
                this.$store.commit('closeDeleteModal');
            }

        }
    };
</script>
