const routes = [
    {
        path: "/program/groups",
        component: () => import("../pages/Index"),
        meta: { isAuthenticated: true,
         permission:'programgroup_index'
        }
    }
];

export default routes;
