<template>
    <div>
        <b-form-group :label="$t('new_password')" class="mb-1">
            <div class="input-icon position-relative">
                <div class="d-flex">
                    <b-input-group-text class="width-130 font-weight-semibold mr-1 pl-2 pr-2" style="font-size: 11px">
                        {{ $t('new_password') }}
                    </b-input-group-text>
                    <div class="flex-grow-1">
                        <b-form-input :type="passwordShowStatus ? 'text':'password'"
                                      v-model="password"
                                      :class="validateError != '' ? 'box-border-color':''"
                                      @input="handleInput"
                        />
                        <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordShowStatus=true"
                           v-if="!passwordShowStatus"
                        />
                        <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordShowStatus=false"
                           v-if="passwordShowStatus"
                        />
                    </div>
                </div>
            </div>
        </b-form-group>
        <b-form-group class="mb-1">
            <div class="input-icon position-relative">
                <div class="d-flex">
                    <b-input-group-text class="width-130 font-weight-semibold mr-1 pl-2 pr-2" style="font-size: 11px">
                        {{ $t('new_password_repeat') }}
                    </b-input-group-text>
                    <div class="flex-grow-1">
                        <b-form-input :type="passwordShowStatus ? 'text':'password'"
                                      v-model="passwordConfirmation"
                                      :class="validateError != '' ? 'box-border-color':''"
                                      @input="handleInput"
                        />
                        <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordShowStatus=true"
                           v-if="!passwordShowStatus"
                        />
                        <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                           @click="passwordShowStatus=false"
                           v-if="passwordShowStatus"
                        />
                    </div>
                </div>
            </div>
        </b-form-group>
        <div class="rules-area border pd-2 rounded">
            <ul class="mt-1">
                <li :class="{ checked: containsEightCharacters }">{{ $t('password_contains_eight_characters_message') }}</li>
                <li :class="{ checked: containsNumber }">{{ $t('password_contains_number_message') }}</li>
                <li :class="{ checked: containsUppercase }">{{ $t('password_contains_uppercase_message') }}</li>
                <li :class="{ checked: containsLowercase }">{{ $t('password_contains_lowercase_message') }}</li>
                <li :class="{ checked: containsSpecialCharacter }">{{ $t('password_contains_special_character_message') }}</li>
                <li :class="{ checked: equalPasswordInputs }">{{ $t('password_equals_message') }}</li>
            </ul>
        </div>
        <b-form-invalid-feedback v-if="validateError" v-html="validateError"></b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        props: {
            validateError: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                password: '',
                passwordConfirmation: '',
                passwordShowStatus: false,

                passwordLenght: 8,
                containsEightCharacters: false,
                containsNumber: false,
                containsUppercase: false,
                containsLowercase: false,
                containsSpecialCharacter: false,

                equalPasswordInputs: false,

                validated: false,
            }
        },
        created() {
        },
        methods: {
            handleInput(event) {
                // 8 Characters
                this.containsEightCharacters = false;
                if (this.password.length >= this.passwordLenght) {
                    this.containsEightCharacters = true;
                }

                // Number
                this.containsNumber = /\d/.test(this.password);

                // Uppercase
                this.containsUppercase = /[A-Z]/.test(this.password);

                // Lowercase
                this.containsLowercase = /[a-z]/.test(this.password);

                // Spacial Chars
                this.containsSpecialCharacter = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);

                this.validated = false;
                this.equalPasswordInputs = false;
                if (
                    this.containsEightCharacters === true &&
                    this.containsNumber === true &&
                    this.containsUppercase === true &&
                    this.containsLowercase === true &&
                    this.containsSpecialCharacter === true
                ){
                    if(this.passwordConfirmation.length>=8 && this.password==this.passwordConfirmation){
                        this.validated = true
                        this.equalPasswordInputs = true
                    }
                }

                if(this.validated){
                    this.$emit('input',this.password)
                }
                else {
                    this.$emit('input','')
                }

            }
        }
    }
</script>
<style scoped>
    .rules-area ul {
        margin-top:15px !important;
    }

    .rules-area li {
        font-weight: 500;
        font-size: 13px;
    }

    .rules-area li.checked {
        color: rgba(85, 197, 122, 0.8);
        text-decoration: line-through;
    }
</style>
