const routes = [
    {
        path: "/logs",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "log_search"
        }
    },
    {
        path: "/logs/exceptions",
        component: () => import("../pages/Exception"),
        meta: {
            isAuthenticated: true,
            permission: "log_search"
        }
    },
];

export default routes;
