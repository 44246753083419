import OnlineRegistrationService from "@/services/OnlineRegistrationService";
import Base from "@/plugins/Base";

const module = {
    namespaced: true,
    state: () => ({
        kvkk: false,
        lbfTab: 0,
        lbfPersonalInfo: {}
    }),
    getters: {
        getKvkk(state){
            return state.kvkk
        },
        getLbfTab(state){
            return state.lbfTab
        },
        getLbfPersonalInfo(state){
            return state.lbfPersonalInfo
        },
    },
    mutations: {
        setKvkk(state, value){
            state.kvkk = value
        },
        setLbfTab(state, value){
            state.lbfTab = value
        },
        setLbfPersonalInfo(state, value){
            state.lbfPersonalInfo = value
        },
        clearData(state){
            state.kvkk = null
            state.lbfTab = null
            state.lbfPersonalInfo = null
        },
    },
    actions: {

        kvkkStore({commit, dispatch, state}) {
            Base.LocalStorage.set('kvkk', true)
            commit("setKvkk", true)

            Base.LocalStorage.set('lbfTab', 0)
            commit("setLbfTab", 0)
        },

        initKvkk({commit, dispatch}) {
            let kvkk = Base.LocalStorage.get('kvkk');
            let lbfTab = Base.LocalStorage.get('lbfTab');
            let lbfPersonalInfo = Base.LocalStorage.get('lbfPersonalInfo');
            if(kvkk){
                commit("setKvkk", kvkk)
                commit("setLbfTab", lbfTab)
                commit("setLbfPersonalInfo", lbfPersonalInfo)
            }
        },

        restoreTab({commit, dispatch}, data) {
            Base.LocalStorage.set('lbfTab',data)
            dispatch("initKvkk")
        },

        restoreLbfPersonalInfo({commit, dispatch}, data) {
            Base.LocalStorage.set('lbfPersonalInfo',data)
            dispatch("initKvkk")
        },

        logout({commit}) {
            commit("clearData")
            Base.LocalStorage.remove('kvkk')
            Base.LocalStorage.remove('lbfTab')
            Base.LocalStorage.remove('lbfPersonalInfo')
        },

    }

}

export default module;
