const routes = [
    {
        path: "/refund/requests",
        component: () => import("../pages/Index"),
        meta: { isAuthenticated: true,
        permission:'refundrequest_index'
        }
    },
    {
        path: "/refund/requests/new",
        component: () => import("../pages/New"),
        meta: {
            isAuthenticated: true,
            permission: "refundrequest_store"
        }
    },
    {
        path: "/refund/requests/:id",
        component: () => import("../pages/View"),
        meta: {
            isAuthenticated: true,
            permission: "refundrequest_show"
        }
    },
    {
        path: "/refunds/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "refund_report"
        }
    },
];

export default routes;
