export default function translateNSelected(values, maxLen = 2) {
    maxLen = Math.max(maxLen, 1)
    const textArr = []
    values.forEach((v) => textArr.push(v.text))
    textArr.sort((a, b) => a.localeCompare(b))

    const extraLen = Math.max(textArr.length - maxLen, 0)
    textArr.length = Math.min(textArr.length, maxLen)
    return `${textArr.join(', ')} ${extraLen > 0 ? `, +${extraLen}` : ''}`
}
