import {setGoReservationSystemForm, getGoReservationSystemForm} from "@/modules/reservationSystemForm/router/middleware";

const routes = [
    {
        path: "/reservation/system/form",
        component: () => import("../pages/Index"),
        meta: {}
    },
    {
        path: "/reservation/system/form/send-email",
        component: () => import("../pages/SendEmail"),
        meta: {},
        beforeEnter: (to, from, next) => {
            if(getGoReservationSystemForm()){
                setGoReservationSystemForm(false)
                next()
            }else{
                next({
                    path: '/reservation/system/form'
                })
            }
        }

    },
];

export default routes;
