<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="current_password" rules="required|min:8" v-slot="{valid, errors}">
                        <b-form-group :label="$t('current_password')">
                            <password-input v-model="current_password"
                                            :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="password" rules="required" v-slot="{valid, errors}">
                        <password-input-with-strength v-model="password" :validateError="errors[0]"/>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="changePassword()"
                          :disabled="formLoading">
                    <b-spinner label="Spinning" class="mr-3" small
                               v-show="formLoading"></b-spinner>
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>

    // Component
    import PasswordInput from '@/components/elements/PasswordInput'
    import PasswordInputWithStrength from '@/components/elements/PasswordInputWithStrength'

    // Services
    import AuthService from '@/services/AuthService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            PasswordInputWithStrength,
            PasswordInput,

            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formLoading: false,

                current_password: null,
                password: null
            }
        },
        methods: {
            async changePassword() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    let formData = {
                        current_password: this.current_password,
                        password: this.password,
                        password_confirmation: this.password
                    }
                    AuthService.changePassword(formData)
                               .then(response => {
                                   this.$store.dispatch('auth/logout');
                               })
                               .catch(e => {
                                   this.showErrors(e, this.$refs.formModalValidate)
                               })
                               .finally(() => {
                                   this.formLoading = false;
                               })

                }
            }
        }
    };
</script>
