const routes = [
    {
        path: "/coopcertificate",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "certificate_index"
        }
    }
];

export default routes;
