const routes = [
    {
        path: "/board/decisions/form",
        component: () => import("../pages/Form"),
        meta: {
            isAuthenticated: true,
            permission: "boarddecision_store"
        }
    },
    {
        path: "/board/decisions/form/:id",
        component: () => import("../pages/Form"),
        meta: {
            isAuthenticated: true,
            permission: "boarddecision_update"
        }
    },
    {
        path: "/board/decisions",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'boarddecision_index'
        }
    },
    {
        path: "/board/decisions/document/:id",
        component: () => import("../pages/Document"),
        meta: {
            isAuthenticated: true,
            permission: "boarddecision_commissiondocument"
        }
    },
];

export default routes;
