const routes = [
    {
        path: "/products",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'product_index'

        }
    }
];

export default routes;
