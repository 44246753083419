export default function setSelected(value = null, options = [], multiple = false, multipleReturnType = 'array') {

    // Options Check
    if (options && options.length == 0) return null;

    // Multiple
    if (multiple) {
        if (multipleReturnType == 'withComma') {
            let data = value ? value.split(","):[];
            if(data.length){
                return options.filter(o => data.some(item => item == o.value));
            }
        }
        else {
            if (value && value.length) {
                return options.filter(o => value.indexOf(o.value) > -1)
            }
        }

        return null;
    }

    // Single
    else if (value) {
        return options.filter((c) => c.value == value);
    }

    return null;
}
