const routes = [
    {
        path: '/make-up/exam',
        component: () => import('../pages/Index.vue'),
        meta: {
            isAuthenticated: true,
            permission:'makeupexam_getmakeupexams'
        }
    },
    {
        path:'/make-up/exam/student',
        component: () => import('../pages/Student.vue'),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            permission:'makeupexam_studentmakeupexam'}
    },
    {
        path:'/make-up/exam/instructor',
        component: () => import('../pages/Instructor.vue'),
        meta: {
            isAuthenticated: true,
            permission:'makeupexam_instructormakeupexam'
        }
    },
    {
        path:'/make-up/exam/letter/entry',
        component: () => import('../pages/makeUpExamEntry'),
        meta: {
            isAuthenticated: true,
            permission:'makeupexam_entrylettergrade'
        }
    }
]

export default routes
