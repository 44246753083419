const routes = [
    {
        path: "/settings/leave/of/absence",
        component: () => import("../pages/LeaveOfAbsence"),
        meta: {
            isAuthenticated: true,
            permission: "settings_leaveofabsence_index"
        }
    },
    {
        path: "/settings/semester",
        component: () => import("../pages/Semester"),
        meta: {
            isAuthenticated: true,
            permission: "settings_semester_index"
        }
    },
    {
        path: "/settings/document",
        component: () => import("../pages/Document"),
        meta: {
            isAuthenticated: true,
            permission: "constant_show"
        }
    },
    {
        path: "/settings/course/and/slot/logs",
        component: () => import("../pages/CourseSlotLogs"),
        meta: {
            isAuthenticated: true,
            permission: "log_courseslotlogs"
        }
    }
];

export default routes;
