const routes = [
    {
        path: "/prep/schedule/drafts",
        component: () => import("../pages/PrepScheduleDraftTable"),
        meta: {
            isAuthenticated: true,
            permission: "prepscheduledraft_index"
        }
    },
    {
        path: "/prep/schedule/drafts/create",
        component: () => import("../pages/Create"),
        meta: {
            isAuthenticated: true,
            permission: "prepscheduledraft_store"
        }
    },

];

export default routes;
