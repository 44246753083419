const routes = [
    {
        path: "/menu",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "menu_index"
        }
    },
    {
        path: "/menu/items/:id",
        component: () => import("../pages/MenuItem"),
        meta: {
            isAuthenticated: true,
            permission: "menuitem_index"
        }
    }
];

export default routes;