const routes = [
    {
        path: "/receipt/search",
        component: () => import("../pages/Search"),
        meta: {
            isAuthenticated: true,
            permission: "payment_collectionreceiptsearch"
        }
    },
];

export default routes;
