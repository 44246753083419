const routes = [
    {
        path: '/double-major/application/quota',
        component: () => import('../pages/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'doublemajorquota_index'
        }
    }
];

export default routes;
