const routes = [
    {
        path: "/sections",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "section_index"
        }
    },
    {
        path: "/sections/close",
        component: () => import("../pages/Close"),
        meta: {
            isAuthenticated: true,
            permission: "section_close"
        }
    },
    {
        path: "/instructor/courses",
        component: () => import("../pages/instructor/Courses"),
        meta: {
            isAuthenticated: true,
            permission: "section_instructor"
        }
    }
];

export default routes;
