const routes = [
    {
        path:'/graduation/in/bulk',
        component:()=>import('../pages/index.vue'),
        meta: {
            isAuthenticated: true,
        }
    }
];

export default routes
