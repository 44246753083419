const routes = [
    {
        path: '/message/box',
        component: () => import('../pages/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'messagereceiver_getusermessages'
        }
    },
];

export default routes;
