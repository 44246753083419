import Api from "@/services/Index";

const logoUpload = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/system/logo", data);
};
const show = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/system/logo", config);
};

export default {
    logoUpload,
    show,
};
