const routes = [
    {
        path: '/course/schedules',
        component: () => import('../pages/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'courseschedule_index'
        }
    },
    {
        path: '/course/schedules/instructor',
        component: () => import('../pages/Instructor'),
        meta: {
            isAuthenticated: true,
            permission: 'courseschedule_instructorcourseschedule'
        }
    },
    {
        path: '/course/schedules/student',
        component: () => import('../pages/student.vue'),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    }
];

export default routes;
