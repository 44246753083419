export default function getAsSpliteProps(value,props=''){
    let checkStrReduce = (values=[])=>{
        let copyValue = value;
        var beforeValue = ''
        for(let i = 0; i<values.length; i++){
            beforeValue = values[i];
            if(copyValue[beforeValue] == null){
                return null
            }
            if(Array.isArray(copyValue[beforeValue]) || (typeof copyValue[beforeValue] === 'object')){
                copyValue = copyValue[beforeValue];
            }
        }
        return copyValue[beforeValue]
    }
    let propsStr = props.split('.');
    return checkStrReduce(propsStr)
}
