export default function toBase64(res) {
    var binary = '';
    var bytes = new Uint8Array(res.data);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    let data = window.btoa(binary)
    return 'data:' + res.headers['content-type'] + ';base64,' + data
}
