const routes = [
    {
        path: "/payments/collection/:payment_plan_id",
        component: () => import("../pages/Collection"),
        meta: {
            isAuthenticated: true,
            permission: "customer_customerpaymentplan"
        }
    },
    {
        path: "/payments/collection/:payment_plan_id/:payment_id",
        component: () => import("../pages/Collection"),
        meta: {
            isAuthenticated: true,
            permission: "customer_customerpaymentplan"
        }
    },
    {
        path: "/payments/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "payment_report"
        }
    },
    {
        path: "/payments/balance-report",
        component: () => import("../pages/BalanceReport"),
        meta: {
            isAuthenticated: true,
            permission: "report_balance"
        }
    },
    {
        path: "/payments/due-date",
        component: () => import("../pages/DueDate"),
        meta: {
            isAuthenticated: true,
            permission: "payment_updateduedate"
        }
    }
];

export default routes;
