const routes = [
    {
        path: '/grading/systems',
        component: () => import('../pages/Index.vue'),
        meta: {
            isAuthenticated: true,
            permission: 'gradingsystem_index'
        }
    }
]

export default routes;
