<template>
    <footer class="mt-auto py-5">
        <div class="container my-4 my-md-5">
            <div class="text-center"></div>
            <div class="d-block d-md-flex justify-content-between">
                <div class="text-black-50 text-center mb-5 mb-md-0">
                    <i class="ri-copyright-line top-plus-2 copyright mr-2"></i
                    >{{ new Date().getFullYear() }} Bahçeşehir Üniversitesi
                </div>
                <div class="social-media text-center text-md-right">
                    <a href="https://facebook.com/bahcesehiruniversity" target="blank" class="text-black-25 ml-3"><i class="ri-lg ri-facebook-fill"></i></a>
                    <a href="https://twitter.com/Bahcesehir" target="blank" class="text-black-25 ml-3"><i class="ri-lg ri-twitter-fill"></i></a>
                    <a href="https://www.instagram.com/bahcesehiruniversity/" target="blank" class="text-black-25 ml-3"><i class="ri-lg ri-instagram-fill"></i></a>
                    <a href="https://www.linkedin.com/school/bahcesehir-university/" target="blank" class="text-black-25 ml-3"><i class="ri-lg ri-linkedin-fill"></i></a>
                    <a href="https://www.youtube.com/user/bahcesehiruniversity" target="blank" class="text-black-25 ml-3"><i class="ri-lg ri-youtube-fill"></i></a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        name: "Footer",
    }
</script>
