const routes = [
    {
        path: "/programs",
        component: () => import("../pages/Index"),
        meta: { isAuthenticated: true,
         permission:'program_index'
        }
    }
];

export default routes;
