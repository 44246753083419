export default function handleInput(value, multiple=false, multipleReturnType = 'array', returnTypeSort=false) {
    // Multiple
    if (multiple) {

        // Null
        if (value.length == 0) {
            this.$emit("input", null)
            return;
        }

        let returnValue = value.length ? value.map(item=>item.value):[]
        if (multipleReturnType == 'array') {
            if(returnTypeSort){
                this.$emit("input", returnValue.sort())
            }
            else {
                this.$emit("input", returnValue)
            }
        }
        if (multipleReturnType == 'withComma') {
            this.$emit("input", returnValue.join(','))
        }
        return;
    }


    // Single
    else if (value && value.value!=null) {
        this.$emit("input", value.value)
        return;
    }

    this.$emit("input", null)
}
