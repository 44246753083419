import Api from '@/services/Index';

const login = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/login', formData);
}

const sendSms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/sendsms', formData);
}
const verifySms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/verifysms', formData);
}

const sendEmail = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/send/email', formData);
}
const verifyEmail = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/verify/email', formData);
}

const storeRegistration = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration', formData);
}

const getFiles = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/filelist', formData);
}
const uploadFile = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/fileupload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
const downloadFile = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/filedownload', formData, {
        responseType: 'blob'
    });
}

const sendApprovalSms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/approval/sms', formData);
}
const approve = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/approve', formData);
}

const checkPaymentStatus = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/online/registration/payment/status', formData);
}

export default {
    login,
    sendSms,
    verifySms,
    sendEmail,
    verifyEmail,
    storeRegistration,
    getFiles,
    uploadFile,
    downloadFile,
    sendApprovalSms,
    approve,
    checkPaymentStatus
}
