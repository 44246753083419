import Base from "@/plugins/Base";
import router from "@/plugins/Router";

const module = {
    namespaced: true,
    state: () => ({
        epaymentToken: null,
        epaymentUser: {
            number: null,
            name: null,
            surname: null
        },
    }),
    getters: {
        getEpaymentToken(state) {
            return state.epaymentToken
        },
        getEpaymentUser(state) {
            return state.epaymentUser
        },
    },
    mutations: {
        setEpaymentToken(state, token) {
            state.epaymentToken = token
        },
        setEpaymentUser(state, user) {
            state.epaymentUser = user
        },
        clear(state) {
            state.epaymentToken = null
            state.epaymentUser = {
                number: null,
                name: null,
                surname: null
            }
        }
    },
    actions: {
        initAuth({commit, dispatch}) {
            let epaymentToken = Base.LocalStorage.get('epayment_token');
            let epaymentUser = Base.LocalStorage.get('epayment_user');
            if (epaymentToken && epaymentUser) {
                commit("setEpaymentToken", epaymentToken)
                commit("setEpaymentUser", epaymentUser)
            }
        },
        logout({commit}) {
            commit("clear")
            Base.LocalStorage.remove('epayment_token')
            Base.LocalStorage.remove('epayment_user')

            router.push("/epayment/login");
        }
    }
}

export default module;
