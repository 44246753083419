import Base from "@/plugins/Base";

const module = {
    namespaced: true,
    state: () => ({
        filters: {},
    }),
    getters: {
        getFilters(state) {
            return state.filters;
        },
    },
    mutations: {
        setFilters(state, value) {
            state.filters = value;
            Base.LocalStorage.set("modules.students.filter", value);
        },
    },
    actions: {
        initLayouts({dispatch}) {
            if(Base.LocalStorage.get("modules.students.filter")) {
                dispatch("setFilters", Base.LocalStorage.get("modules.students.filter"));
            }
        },
        setFilters({commit}, value) {
            commit('setFilters', value)
        }
    }
}

export default module;
