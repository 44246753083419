const routes = [
    {
        path: '/leave/with/consents',
        component: () => import('../pages/admin/Index'),
        meta: {
            isAuthenticated: true,
            permission: "leavewithconsent_index"
        }
    },
    {
        path: '/leave/with/consents/:id',
        component: () => import('../pages/admin/Show'),
        meta: {
            isAuthenticated: true,
            permission: "leavewithconsent_show"
        }
    },
    {
        path: '/leave/with/consents/student/form',
        component: () => import('../pages/student/Index'),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    }
]

export default routes;
