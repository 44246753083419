const routes = [
    {
        path: "/contactCreate",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentprogram_contactinformationreport"
        }
    }
];

export default routes;
