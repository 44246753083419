const routes = [
    {
        path: "/housing/reservations/list",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            // permission: 'housingreservation_reservationlist'
        }
    }
];

export default routes;
