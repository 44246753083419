const routes = [
    {
        path: '/undergraduate/transfer/quota',
        component: () => import('../pages/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'undergraduatetransferquota_index'
        }
    }
]

export default routes;
