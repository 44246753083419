const routes = [
    {
        path: "/university/grades",
        component: () => import("../pages/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "universitygrade_index"
        }
    }
];

export default routes;
