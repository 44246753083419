const routes = [
    {
        path: '/student/programs/medicine/groups',
        component: () => import('../pages/MedicineGroups'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_medicinegroup'
        }
    },
    {
        path: '/student/programs/update-classes',
        component: () => import('../pages/UpdateClassesJob'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_updateclasses'
        }
    },
    {
        path: '/student/programs/class-success',
        component: () => import('../pages/ClassSuccess'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_classsuccess'
        }
    },
    {
        path: '/student/programs/graduation-success',
        component: () => import('../pages/GraduationSuccess'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_graduatesuccess'
        }
    },
    {
        path: '/student/programs/graduation-requirement-report',
        component: () => import('../pages/GraduationRequirementReport'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_graduationrequirementreport'
        }
    },
    {
        path: '/student/programs/:id/slots',
        component: () => import('../pages/slots/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_studentslot'
        }
    },
    {
        path: '/student/programs/:id/slots/edit',
        component: () => import('../pages/slots/Edit'),
        meta: {
            isAuthenticated: true,
            permission: 'studentslot_update'
        }
    },
    {
        path: '/my-slots',
        component: () => import('../pages/slots/Index'),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            permission: "studentprogram_studentslot"
        }
    },
    {
        path: '/my-requests/:tab?',
        component: () => import('../pages/Requests'),
        meta: {
            isAuthenticated: true,
            //isStudent: true
        }
    }
]

export default routes;
