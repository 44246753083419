import OnlineRegistrationService from "@/services/OnlineRegistrationService";
import Base from "@/plugins/Base";

const module = {
    namespaced: true,
    state: () => ({
        dashboard: {},
        orToken: null,
        fileTypes: null,
        registration: null,
        disabledTypes: null,
        mobileVerify: false,
        emailVerify:false,
        cities: null,
        jobs: null,
        leaveOfAbsenceReasons: null,
        leaveOfAbsenceFall: null,
        leaveOfAbsenceSpring: null,
        englishProficiencyCertificateTypes: null
    }),
    getters: {
        getOrToken(state){
            return state.orToken
        },
        getFileTypes(state){
            return state.fileTypes
        },
        getRegistration(state){
            return state.registration
        },
        isAuthenticated(state) {
            return state.orToken ? true : false
        },
        getDisabledTypes(state){
            return state.disabledTypes
        },
        getMobileVerify(state){
            return state.mobileVerify
        },
        getEmailVerify(state){
            return state.emailVerify
        },
        getCities(state){
            return state.cities
        },
        getJobs(state){
            return state.jobs
        },
        getLeaveOfAbsenceReasons(state){
            return state.leaveOfAbsenceReasons
        },
        getEnglishProficiencyCertificateTypes(state){
            return state.englishProficiencyCertificateTypes
        },
        getLeaveOfAbsenceFall(state){
            return state.leaveOfAbsenceFall
        },
        getLeaveOfAbsenceSpring(state){
            return state.leaveOfAbsenceSpring
        },
    },
    mutations: {
        setToken(state, orToken){
            state.orToken = orToken
        },
        setFileTypes(state, fileTypes){
            state.fileTypes = fileTypes
        },
        setRegistration(state, registration){
            state.registration = registration
        },
        clearData(state){
            state.orToken = null
            state.fileTypes = null
            state.registration = null
        },
        setDisabledTypes(state, disabledTypes){
            state.disabledTypes = disabledTypes
        },
        setMobileVerify(state, bool){
            state.mobileVerify = bool
        },
        setEmailVerify(state, bool){
            state.emailVerify = bool
        },
        setCities(state, cities){
            state.cities = cities
        },
        setJobs(state, jobs){
            state.jobs = jobs
        },
        setLeaveOfAbsenceReasons(state, leaveOfAbsenceReasons){
            state.leaveOfAbsenceReasons = leaveOfAbsenceReasons
        },
        setEnglishProficiencyCertificateTypes(state, englishProficiencyCertificateTypes){
            state.englishProficiencyCertificateTypes = englishProficiencyCertificateTypes
        },
        setLeaveOfAbsenceFall(state, leaveOfAbsenceFall){
            state.leaveOfAbsenceFall = leaveOfAbsenceFall
        },
        setLeaveOfAbsenceSpring(state, leaveOfAbsenceSpring){
            state.leaveOfAbsenceSpring = leaveOfAbsenceSpring
        },
    },
    actions: {

        login({commit, dispatch, state}, authData) {
            return OnlineRegistrationService.login(authData).then(response => {
                let data = response.data.data;
                if (data.access_token) {
                    Base.LocalStorage.set('or_access_token',data.access_token);
                    commit("setToken", data.access_token)
                    ///File Types
                    Base.LocalStorage.set('or_file_types',data.file_types)
                    commit("setFileTypes", data.file_types)
                    ///Registration Data
                    Base.LocalStorage.set('or_registration',data.registration)
                    commit("setRegistration", data.registration)
                    ///Disabled Types
                    Base.LocalStorage.set('or_disabled_types',data.disabled_types)
                    commit("setDisabledTypes", data.disabled_types)
                    //Mobile Verify
                    Base.LocalStorage.set('or_mobile_verify',false)
                    commit("setMobileVerify",false)
                    //Email Verify
                    Base.LocalStorage.set('or_email_verify',false)
                    commit("setEmailVerify",false)
                    //Cities
                    Base.LocalStorage.set('or_cities',data.cities)
                    commit("setCities",data.cities)
                    //Jobs
                    Base.LocalStorage.set('or_jobs',data.jobs.items)
                    commit("setJobs",data.jobs.items)
                    //Leave Of Absence Reasons
                    Base.LocalStorage.set('or_leave_of_absence_reasons',data.leave_of_absence_reasons.items)
                    commit("setLeaveOfAbsenceReasons",data.leave_of_absence_reasons.items)
                    //English Proficiency Certificate Types
                    Base.LocalStorage.set('or_english_proficiency_certificate_types',data.english_proficiency_certificate_types.items)
                    commit("setEnglishProficiencyCertificateTypes",data.english_proficiency_certificate_types.items)

                    Base.LocalStorage.set('leave_of_absence_fall',data.leave_of_absence_fall)
                    commit("setLeaveOfAbsenceFall",data.leave_of_absence_fall)

                    Base.LocalStorage.set('leave_of_absence_spring',data.leave_of_absence_spring)
                    commit("setLeaveOfAbsenceSpring",data.leave_of_absence_spring)
                }
            })
        },

        initAuth({commit, dispatch}) {
            let orToken = Base.LocalStorage.get('or_access_token');
            if(orToken){
                commit("setToken", orToken)
                let fileTypes = Base.LocalStorage.get('or_file_types')
                commit("setFileTypes", fileTypes)
                let registration = Base.LocalStorage.get('or_registration')
                commit("setRegistration", registration)
                let disabledTypes = Base.LocalStorage.get('or_disabled_types')
                commit("setDisabledTypes", disabledTypes)
                let mobileVerify = Base.LocalStorage.get('or_mobile_verify')
                commit("setMobileVerify", mobileVerify)
                let emailVerify = Base.LocalStorage.get('or_email_verify')
                commit("setEmailVerify", emailVerify)
                let cities = Base.LocalStorage.get('or_cities')
                commit("setCities", cities)
                let jobs = Base.LocalStorage.get('or_jobs')
                commit("setJobs", jobs)
                let leaveOfAbsenceReasons = Base.LocalStorage.get('or_leave_of_absence_reasons')
                commit("setLeaveOfAbsenceReasons", leaveOfAbsenceReasons)
                let englishProficiencyCertificateTypes = Base.LocalStorage.get('or_english_proficiency_certificate_types')
                commit("setEnglishProficiencyCertificateTypes", englishProficiencyCertificateTypes)

                let leaveOfAbsenceFall = Base.LocalStorage.get('leave_of_absence_fall')
                commit("setLeaveOfAbsenceFall", leaveOfAbsenceFall)

                let leaveOfAbsenceSpring = Base.LocalStorage.get('leave_of_absence_spring')
                commit("setLeaveOfAbsenceSpring", leaveOfAbsenceSpring)
            }
        },

        logout({commit}) {
            commit("clearData")
            Base.LocalStorage.remove('or_access_token')
            Base.LocalStorage.remove('or_file_types')
            Base.LocalStorage.remove('or_registration')
            Base.LocalStorage.remove('or_disabled_types')
            Base.LocalStorage.remove('or_mobile_verify')
            Base.LocalStorage.remove('or_email_verify')
            Base.LocalStorage.remove('or_cities')
            Base.LocalStorage.remove('or_jobs')
            Base.LocalStorage.remove('or_leave_of_absence_reasons')
            Base.LocalStorage.remove('leave_of_absence_fall')
            Base.LocalStorage.remove('leave_of_absence_spring')
            Base.LocalStorage.remove('or_english_proficiency_certificate_types')
        },

        restoreRegistration({commit, dispatch}, data) {
            Base.LocalStorage.set('or_registration',data)
            dispatch("initAuth")
        },

        restoreRegistrationMobile({commit, dispatch}, mobileTel) {
            let registration = Base.LocalStorage.get('or_registration')
            let mobileVerify = Base.LocalStorage.get('or_mobile_verify')
            registration.mobile_tel = mobileTel
            mobileVerify = true
            Base.LocalStorage.set('or_registration',registration)
            Base.LocalStorage.set('or_mobile_verify',mobileVerify)
            dispatch("initAuth")
        },

        restoreRegistrationEmail({commit, dispatch}, email) {
            let registration = Base.LocalStorage.get('or_registration')
            let emailVerify = Base.LocalStorage.get('or_email_verify')
            registration.email = email
            emailVerify = true
            Base.LocalStorage.set('or_registration',registration)
            Base.LocalStorage.set('or_email_verify',emailVerify)
            dispatch("initAuth")
        }

    }

}

export default module;
