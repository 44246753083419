const routes = [
    {
        path: "/double-course-control",
        component: () => import("../pages/Index"),
        meta: { 
            isAuthenticated: true,
            permission:'examschedule_doublecoursecontrol'
        }
    }
];

export default routes;
