const routes = [
    {
        path: "/payment/plans/create",
        component: () => import("../pages/Create"),
        meta: {
            isAuthenticated: true,
            permission: "paymentplan_create"
        }
    },
    {
        path: "/payment/plans/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "paymentplan_report"
        }
    },
    {
        path: "/payment/plans/due-date",
        component: () => import("../pages/DueDate"),
        meta: {
            isAuthenticated: true,
            permission: "paymentplan_updateduedate"
        }
    },
    {
        path: "/payment/plans/approvals",
        component: () => import("../pages/Approval"),
        meta: {
            isAuthenticated: true,
            permission: "paymentplan_approvaljob"
        }
    }
];

export default routes;
