import i18n from '@/plugins/i18n'

export default function getYesNoText(status, nullResposense='') {
    if(status=='E'){
        return i18n.t('yes');
    }
    else if(status=='H') {
        return i18n.t('no');
    }

    return nullResposense;

}
