const routes = [
    {
        path: "/user/model/filters",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'usermodelfilter_index'
        }
    }
];

export default routes;
