import i18n from '@/plugins/i18n'

export default function getDayText(day) {
    if (day === 1) {
        return i18n.t('monday');
    }
    else if (day === 2) {
        return i18n.t('tuesday');
    }
    else if (day === 3) {
        return i18n.t('wednesday');
    }
    else if (day === 4) {
        return i18n.t('thursday');
    }
    else if (day === 5) {
        return i18n.t('friday');
    }
    else if (day === 6) {
        return i18n.t('saturday');
    }
    else if (day === 7) {
        return i18n.t('sunday');
    }
    else {
        return '';
    }
}
