const routes = [
    {
        path: "/exam/schedules",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'examschedule_index'
        }
    },
    {
        path: "/exam/schedules/student",
        component: () => import("../pages/student.vue"),
        meta: {
            isAuthenticated: true,
            permission:'examschedule_studentexamschedule'
        }
    },
    {
        path: "/exam/schedules/find-suitable-time",
        component: () => import("../pages/findSuitableTime"),
        meta: {
            isAuthenticated: true,
            permission:'examschedule_index'
        }
    }
];

export default routes;
