const routes = [
    {
        path: "/availability/rooms",
        component: () => import("../pages/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "housingbed_available"
        }
    },
];

export default routes;
