const routes = [
    {
        path: '/minor/application/quota',
        component: () => import('../pages/Index'),
        meta: {
            isAuthenticated: true,
            permission: "minorapplicationquota_index"
        }
    }
];

export default routes;
