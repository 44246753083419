const routes = [
    {
        path: "/buildings",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission:'building_index'
        }
    }
];

export default routes;
