<template>
    <BButton @click="clicked"
             ref="configedBtn"
             type="button"
             :variant="variant"
             :block="block"
             :disabled="disable"
             :class="{disable}"
    >

        <slot>{{ $t(label) | toUpperCase }}</slot>
    </BButton>
</template>
<script>
import Vue from 'vue'

export default {
    inheritAttrs: true,
    props: {
        label: {
            default: "save",
        },
        variant: {
            default: "primary"
        },
        block: {
            default: false
        },
        longRequest:{
            default:false
        }
    },
    data() {
        return {
            disable: false,
            icon:{},
            marginWhenIcon:'10px',
            buttonInnerText:''
        }
    },
    methods: {
        clicked() {
            this.icon = this.$refs["configedBtn"].querySelector('i');
            this.span = this.$refs["configedBtn"].querySelector('span');
            this.buttonInnerText = this.$refs["configedBtn"].innerText;
            Vue.prototype.currentButton = this;
            this.$emit('click')
            if(this.longRequest) return
            //this.defaultSpinner()

        },
        defaultSpinner(){
            setTimeout(()=>{
                this.disable=false
            },5000)
        },
    },
    watch:{
        disable:function (value){
            /*if(value==true){
                this.$refs["configedBtn"].style.padding = '0px';
                if(this.icon){
                    if(!this.buttonInnerText){
                        this.marginWhenIcon = '0px';
                    }
                    this.icon.style.display = 'none';
                }
            }
            else{
                this.$refs["configedBtn"].style.padding = '0px 0.9rem';
                if(this.icon){
                    this.icon.style.display = 'inline-block';
                    this.marginWhenIcon = '10px';
                }
            }*/
        }
    }
}
</script>
<style>
.centerSpinner{
    justify-content: center !important;
}
</style>
