const routes = [
    {
        path: "/prep/students/from/placement",
        component: () => import("../pages/Placement"),
        meta: {
            isAuthenticated: true,
            permission: "prepstudent_createclasses"
        }
    },
    {
        path: "/prep/students/from/excel",
        component: () => import("../pages/Excel"),
        meta: {
            isAuthenticated: true,
            permission: "prepstudent_createclassesfromexcel"
        }
    },
    {
        path: "/prep/students",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "prepstudent_index"
        }
    }
];

export default routes;
