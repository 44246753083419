const routes = [
    {
        path: "/product/discounts",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission:'productdiscount_index'

        }
    }
];

export default routes;
