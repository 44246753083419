import {getHousingFormRoutePage,setHousingFormRoutePage} from "@/modules/housingAgreementForm/router/middleware";

const routes = [
    {
        path: "/housing/agreement-form/:token",
        component: () => import("../pages/Index"),
        meta: {}
    },
    {
        path: "/housing/form/success",
        component: () => import("../pages/SuccessPage.vue"),
        meta: {},
        beforeEnter: (to, from, next) => {
            if(getHousingFormRoutePage()){
                setHousingFormRoutePage(false)
                next()
            }else{
                next({
                    path: '/housing/tenant/login'
                })
            }
        }
    }
]

export default routes;
