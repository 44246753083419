import Base from '@/plugins/Base';
import router from '@/plugins/Router';
import UserService from '@/services/UserService'
import toBase64 from '@/helpers/toBase64'
import getAsSpliteProps from "@/helpers/getObjectValueSplitProps";
import isset from 'isset-php'

const module = {
    namespaced: true,
    state: () => ({
        user: {},
        photo: null,

        token: null,
        tokenExpiresIn: null,

        menus: [],
        activePage: 'login',

        notifications: []
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        getPhoto(state) {
            if(state.photo && state.photo!='-') {
                return state.photo;
            }
            else {
                return null;
            }
        },
        getToken(state) {
            return state.token;
        },
        getTokenExpiresIn(state) {
            return state.tokenExpiresIn;
        },
        getMenus(state) {
            return state.menus;
        },
        getActivePage(state) {
            return state.activePage;
        },
        getNotifications(state) {
            return state.notifications;
        },

        // Auth
        isAuthenticated(state) {
            return state.token ? true : false
        },

        // Role
        getActiveRole(commit) {
            if (isset(() => commit.user.active_role.name)) {
                return commit.user.active_role.name
            }
            return false;
        },
        isStudent(state, getters) {
            let role = getters.getActiveRole;
            return role === 'OGR';
        },
        isAlumni(state, getters) {
            let role = getters.getActiveRole;
            return role === 'ALUMNI';
        },
        isStudentOrAlumni(state, getters) {
            return this.isStudent(state, getters) || this.isAlumni(state, getters);
        },
        isAdmin(state, getters) {
            let role = getters.getActiveRole;
            return role === 'A';
        }
    },
    mutations: {
        setUser(state, user) {
            Base.LocalStorage.set('user', user);
            state.user = user;
        },

        // Photo
        setPhoto(state, photo) {
            Base.LocalStorage.set('photo', photo);
            state.photo = photo;
        },
        clearPhoto(state) {
            Base.LocalStorage.remove('photo');
            state.photo = null;
        },

        // Menus
        setMenus(state, menus) {
            Base.LocalStorage.set('menus', menus);
            let user = Base.LocalStorage.get('user');

            let configMenus = function () {
                var newMenus = menus
                //Lisan ve ÖnLisan Öğrenciler için
                if(['ol','l'].includes(getAsSpliteProps(user,'active_student_program.program_level'))) {
                    newMenus[0].items = newMenus[0].items.filter(menu => menu.id !== 92);
                    return newMenus
                }
                //Yüksek Lisans ve Doktora öğrencileri için Tez Menüsünün görünmesi
                if(!(getAsSpliteProps(user,'active_student_program.with_thesis') &&
                    ['232','207','216','218','219','234','235'].includes(getAsSpliteProps(user,'active_student_program.registration_type')))){

                    newMenus[0].items = newMenus[0].items.filter(menu => menu.id !== 142);
                    return newMenus

                }
                return newMenus
            }

            state.menus = configMenus();
        },
        clearMenus(state) {
            Base.LocalStorage.remove('menus');
            state.menus = []
        },

        // Token
        setToken(state, token) {
            state.token = token;
        },

        // Token Expires
        setTokenExpiresIn(state, tokenExpiresIn) {
            state.tokenExpiresIn = tokenExpiresIn;
        },

        // Clear
        clearUserAndToken(state) {
            state.user = {}
            state.token = null;
        },

        setActivePage(state, page) {
            state.activePage = page;
        }

    },
    actions: {
        initAuthPage() {
            let token = Base.LocalStorage.get('access_token');
            let {kvkk_status} = (Base.LocalStorage.get('user')||{});
            if (token && kvkk_status) {
                router.push('/dashboard');
            }
        },

        initAppPage({commit, dispatch}) {

            let token = Base.LocalStorage.get('access_token');
            let tokenExpiresIn = Base.LocalStorage.get('access_token_expires_in');
            if (token && tokenExpiresIn) {
                let time = new Date().getTime();
                if (time >= +tokenExpiresIn) {
                    dispatch('logout')
                }

                dispatch('initUser', token);
                dispatch('initMenus');
                dispatch('initPhoto');

                let timerSecond = +tokenExpiresIn - time;
                dispatch('setTimeoutTimer', timerSecond)
            }
        },

        // User
        initUser({commit, dispatch}, token) {

            let user = Base.LocalStorage.get('user');
            if (user) {
                commit('setUser', user);
            }

            dispatch('getUser').then(() => {
                commit('setToken', token);
            })
        },

        // Menus
        initMenus({commit, dispatch}) {

            let menus = Base.LocalStorage.get('menus');

            if (menus) {
                commit('setMenus', menus);
            } else {
                dispatch('getMenus');
            }
        },

        // Photo
        initPhoto({commit, dispatch}) {

            let photo = Base.LocalStorage.get('photo')
            if (typeof photo != 'undefined' && photo) {
                commit('setPhoto', photo);
            }
            else {
                dispatch('getPhoto');
            }
        },

        async getUser({commit}) {
            await UserService.usersMe()
                             .then(response => {
                                 let data = response.data.data;
                                 commit('setUser', data);
                             })
        },
        getMenus({commit}) {
            UserService.menu()
                       .then(responseUser => {
                           let data = responseUser.data.data;
                           commit('setMenus', data);
                       })
        },
        getPhoto({commit}) {
            UserService.photo()
                       .then(response => {
                           if (response.status == 200) {
                               let base64 = toBase64(response);
                               if(base64.length<100){
                                   base64 = '-';
                               }
                               commit('setPhoto', base64);
                           }
                           else{
                               commit('setPhoto','-');
                           }
                       })
        },

        // Active Role
        getActiveRole({state}) {
            if (isset(() => state.user.active_role.name)) {
                return state.user.active_role.name
            }
            return false;
        },

        clearUserInfoAndToken({commit}) {
            commit('clearUserAndToken');
            commit('clearMenus');
            commit('clearPhoto');
            Base.LocalStorage.remove('user');
            Base.LocalStorage.remove('access_token');
            Base.LocalStorage.remove('access_token_expires_in');
            if ( Base.LocalStorage.get('token')){
                Base.LocalStorage.remove('token');
            }

        },

        logout({dispatch}) {
            dispatch('clearUserInfoAndToken');
            if (router.currentRoute.path != '/login') {
                router.push('/login');
            }
        },

        setTimeoutTimer({dispatch}, expiresIn) {
            /*
             setTimeout(() => {
             dispatch('logout')
             }, expiresIn);
             */
        },

        restoreUserInfo({commit, dispatch}, data) {
            Base.LocalStorage.set('user',data)
        },
    }
}

export default module;
