<template>
    <div v-show="dataShowTable">
        <h6 v-if="showTitle">{{ title }}</h6>
        <div class="mb-0 table-dropdown border rounded">
            <vue-good-table
                :mode="mode"
                @on-page-change="$emit('on-page-change', $event)"
                @on-sort-change="$emit('on-sort-change', $event)"
                @on-column-filter="$emit('on-column-filter', $event)"
                @on-per-page-change="$emit('on-per-page-change', $event)"
                @on-cell-click="$emit('on-cell-click', $event)"
                @on-selected-rows-change="$emit('on-selected-rows-change', $event)"
                :styleClass="styleClass"
                :totalRows="dataTotal"
                :isLoading="dataIsLoading"
                :rows="dataRows"
                :columns="computedColumns"
                :pagination-options="paginationOptions"
                :sort-options="sortOptions"
                :line-numbers="lineNumbers"
                :select-options="{ enabled: selectOptions,
                                   selectOnCheckboxOnly: true,
                                   selectionText: $t('rows_selected'),
                                   clearSelectionText: $t('clear') }"
                :responsive="true"
            >
                <div slot="selected-row-actions">
                    <b-button variant="primary" @click="$emit('selected-bulk-action', $event)">{{selectedActionButtonText}}</b-button>
                </div>
                <template slot="loadingContent">
                    <div>{{ $t('loading') }}</div>
                </template>
                <template slot="table-column" slot-scope="props">
                    <span v-html="props.column.label.toUpper()"></span>
                    <span v-if="props.column.field === 'buttons' && dataFunction">
                        <b-dropdown variant="link btn-sm" boundary="window" no-caret >
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <div id="dropdownListHead">
                                <b-dropdown-item @click="excel">
                                    <i class="ri-file-excel-line align-middle top-minus-1 mr-3 text-muted"></i> Excel
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                    </span>
                </template>
                <template slot="table-row" slot-scope="props" >
                    <span v-if="props.column.field === 'buttons' && editMode">
                        <b-dropdown variant="link btn-sm"
                                    boundary="window"
                                    ref="dropdown"
                                    no-caret

                        >
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <div id="dropdownList">
                                <b-dropdown-item v-for="button in props.column.buttons"
                                                 :key="button.text.toLowerCase() + props.row.id"
                                                 @click="button.callback(props.row)"
                                                 v-if="checkShow(button, props.row)"

                                >
                                    <i :class="button.class + ' align-middle top-minus-1 mr-3 text-muted'"></i>
                                    {{ button.text }}
                                </b-dropdown-item>
                            </div>
                        </b-dropdown>
                    </span>
                    <span v-else v-html="props.formattedRow[props.column.field]"></span>
                </template>
                <div slot="emptystate">
                    <div class="text-center my-3">{{ $t('no_info_found') }}</div>
                </div>
            </vue-good-table>
        </div>
    </div>
</template>
<script>
    import i18n from '@/plugins/i18n';
    import qs from 'qs';

    export default {
        props: {
            mode: {
                default: 'remote',
                type: String
            },
            dataFunction: {
                type: Function
            },
            isLoading: {
                default: false,
                type: Boolean
            },
            showTable: {
                default: false,
                type: Boolean
            },
            columns: {},
            rows: {},
            lineNumbers: {
                default: false,
                type: Boolean
            },
            total: {
                default: 0,
                type: Number
            },
            queryParams: {
                default: () => ({
                    filter: {},
                    sort: {},
                    page: 1,
                    limit: 15,
                    excel: false,
                    columns: null
                })
            },
            styleClass: {
                default: 'vgt-table table b-table table-striped table-bordered border-0',
                type: String
            },
            sortOptions: {},
            showPaginate: {
                type: Boolean,
                default: true
            },
            editMode: {
                type: Boolean,
                default: true
            },
            showTitle: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: () => {
                    return i18n.t('results')
                }
            },
            selectOptions:{
                type:Boolean,
                default:()=>false
            },
            selectedActionButtonText:{
                type: String,
                default: 'Button'
            }
        },
        computed: {
            computedColumns() {
                return this.columns.map(item => {
                    return {
                        ...item,
                        sortable: item.sortable ? item.sortable : false
                    }
                })
            },
            paginationOptions() {
                return {
                    enabled: this.showPaginate,
                    mode: 'records',
                    perPage: this.queryParams && this.queryParams.limit ? this.queryParams.limit : 10,
                    position: 'bottom',
                    perPageDropdown: [5, 10, 15, 20, 40, 50],
                    dropdownAllowAll: true,
                    setCurrentPage: this.queryParams && this.queryParams.page ? this.queryParams.page : 1,
                    jumpFirstOrLast: true,
                    firstLabel: '',
                    lastLabel: '',
                    nextLabel: '',
                    prevLabel: '',
                    rowsPerPageLabel: '',
                    ofLabel: ', ',
                    dropdownShow: false
                }
            }
        },
        data() {
            return {
                dataRows: this.rows,
                dataShowTable: this.showTable,
                dataIsLoading: this.isLoading,
                dataTotal: this.total,
                excelQueryParams: null
            };
        },
        watch: {
            rows(newVal) {
                this.dataRows = newVal;
            },
            total(newVal) {
                this.dataTotal = newVal;
            },
            isLoading(newVal) {
                this.dataIsLoading = newVal;
            },
            showTable(newVal) {
                this.dataShowTable = newVal;
            }
        },
        methods: {
            dropdownShow() {
                this.dropdownShow = true;
            },
            checkShow(button, row) {
                return (typeof button.show == 'function'
                    ? button.show(row)
                    : true) && (typeof button.permission != 'undefined' ? this.checkPermission(button.permission) : true)
            },
            excel() {
                this.excelQueryParams = {...this.queryParams};
                this.excelQueryParams.excel = true;
                this.excelQueryParams.file_name = document.title.split('|')[0].trim();
                this.excelQueryParams.columns = this.columns
                                               .filter((item) => {
                                                   return item.field != 'buttons';
                                               })
                                               .map((item) => {
                                                   return {field: item.field, label: item.label};
                                               });
                this.getRows();
                this.excelQueryParams = null;
            },
            getRows() {
                this.dataShowTable = true;
                this.dataIsLoading = true;

                const config = {
                    params: {
                        ...(this.excelQueryParams ? this.excelQueryParams : this.queryParams)
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                let isExcel = (this.excelQueryParams!=null && this.excelQueryParams.excel===true);

                if (isExcel === true) {
                    config.responseType = 'arraybuffer';
                    config.params.limit = -1;
                    let columns = config.params.columns;
                    columns.forEach(function (column, i){
                        column.label = column.label.replaceAll('<br>', ' ');
                    });

                    config.params.columns = columns;
                }
                else {
                    delete config.params.excel;
                    delete config.params.columns;
                }

                this.dataFunction(config)
                    .then((response) => {
                        if (!isExcel) {
                            this.dataRows = response.data.data;
                            this.dataTotal = response.data.pagination.total;
                        }
                        else {
                            this._downloadFile(response, document.title.split('|')[0].trim() + '.xlsx');
                        }
                    })
                    .finally(() => {
                        this.dataIsLoading = false;
                    });
            },
            filterClear() {
                this.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.queryParams.limit = params.currentPerPage;
                this.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.columns[key].hidden = this.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.queryParams.sort = sortType + params[0].field
                this.getRows();
            }
        },
        created() {
            if (this.dataFunction) {
                let arr = Object.keys(this.$listeners);
                if (!arr.includes('on-page-change')) {
                    this.$on('on-page-change', this.onPageChange);
                }
                if (!arr.includes('on-sort-change')) {
                    this.$on('on-sort-change', this.onSortChange);
                }
                if (!arr.includes('on-per-page-change')) {
                    this.$on('on-per-page-change', this.onPerPageChange);
                }
            }
        }
    }
</script>
<style>
.dropdown-menu{
    z-index: 99999 !important;
}
</style>

