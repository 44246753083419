import SystemLogoService from "@/services/SystemLogoService";

function beforeBase64(type) {
    return `data:image/${type};base64,`;
}

const module = {
    namespaced: true,
    state: () => ({
        dashboard: {},
        images: null,
    }),
    getters: {
        getImage: (state) => (name) => {
            if (!name || !state.images || !state.images[name]) return false;
            const type = name === "favicon" ? "x-icon" : "png";
            return beforeBase64(type) + state.images[name];
        },
    },
    mutations: {
        setImages(state, data) {
            state.images = data;
        },
    },
    actions: {
        async getAppImages({ commit }) {
            const response = await SystemLogoService.show();
            commit("setImages", response.data);
        },
    },
};

export default module;
