const routes = [
    {
        path: "/certificate",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "certificate_index"
        }
    },
    {
        path: "/certificate/courses/:id",
        component: () => import("../pages/CertificateCourses"),
        meta: {
            isAuthenticated: true,
            permission: "certificatecourse_index"
        },
    },
];

export default routes;
