const routes = [
    //Student
    {
        path: "/thesis/and/project",
        component: () => import("../pages/StudentForm"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    //Admin
    {
        path: "/thesis/and/project/advisor/approve",
        component: () => import("../pages/AdvisorApprove"),
        meta: {
            isAuthenticated: true,
            permission: "thesisandproject_advisorindex"
        }
    },
    //Student
    {
        path: "/thesis/and/project/student",
        component: () => import("../pages/StudentApprove"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    //Admin
    {
        path: "/thesis/and/project/admin/approve",
        component: () => import("../pages/AdminApprove"),
        meta: {
            isAuthenticated: true,
            permission: "thesisandproject_index"
        }
    },
    {
        path: "/thesis/and/project/coordinator/approve",
        component: () => import("../pages/CoordinatorApprove"),
        meta: {
            isAuthenticated: true,
            permission: "thesisandproject_coordinatorindex"
        }
    }
];

export default routes;
