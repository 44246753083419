const routes = [
    {
        path: "/update-contact-info",
        component: () => import("../pages/UpdateContactInfo"),
        meta: {
            isAuthenticated: true,
            //permission: "person_verifymobiletel"
            isUpdateContactInfo: true
        }
    }
];

export default routes;
