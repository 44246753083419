const routes = [
    {
        path: "/user",
        component: () => import("./../pages/Index"),
        children: [
            {
                path: "about",
                component: () => import("./../pages/About"),
            },
        ],
        meta: { isAuthenticated: true }
    },
    {
        path: "/login-as",
        component: () => import("./../pages/LoginAs"),
        meta: { isAuthenticated: true , permission: "user_loginas",}
    },
    {
        path: "/user-transfer",
        component: () => import("./../pages/UserTransfer"),
        meta: { isAuthenticated: true, permission: "user_usertransfer"}
    }
];

export default routes;
