const routes = [
    {
        path: "/job/logs/me",
        component: () => import("../pages/Me"),
        meta: {
            isAuthenticated: true,
            permissions: "joblog_me"
        }
    },
    {
        path: "/job/logs",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "joblog_index"
        }
    },
    {
        path: "/job/logs/detail/:log_id",
        component: () => import("../pages/LogDetail"),
        meta: {
            isAuthenticated: true,
            permission: "joblog_show"
        }
    },
    {
        path: "/jobs/start",
        component: () => import("../pages/Start"),
        meta: {
            isAuthenticated: true,
            permission: "joblog_index"
        }
    },
];

export default routes;
