const routes = [
    {
        path: "/minor/application/preferences/student-affairs",
        component: () => import("../pages/studentAffairs/Index"),
        meta: {
            isAuthenticated: true,
            permission: "minorapplicationpreference_indexforstudentaffairs"
        }
    },
    {
        path: "/minor/application/preferences/prep-school",
        component: () => import("../pages/prepSchool/Index"),
        meta: {
            isAuthenticated: true,
            permission: "minorapplicationpreference_indexforprepschool"
        }
    },
    {
        path: "/minor/application/preferences/academic-unit-office",
        component: () => import("../pages/academicUnitOffice/Index"),
        meta: {
            isAuthenticated: true,
            permission: "minorapplicationpreference_indexforacademicunitoffice"
        }
    }
];

export default routes;
