const routes = [
    {
        path: "/prep/classes",
        component: () => import("../pages/PrepClassesTable"),
        meta: {
            isAuthenticated: true,
            permission: "prepclass_index"
        }
    },

];

export default routes;
