const routes = [
    {
        path: "/placement/exam/part/classrooms/:id",
        component: () => import("../pages/PlacementExamPartClassroomsTable"),
        meta: {
            isAuthenticated: true,
            permission: "placementexampartclassroom_index"
        }
    },

];

export default routes;
