const routes = [
    {
        path: '/exam/data/collection',
        component:()=> import('../pages/index.vue'),
        meta: {
            isAuthenticated: true,
        }
    }
];

export default routes
